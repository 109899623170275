import './assets/main.css'
import { createI18n } from "vue-i18n";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import "@shared/tailwind.css";
import "@shared/styles.css";
import "element-plus/dist/index.css";

import "./assets/main.css";

import App from './App.vue'
import router from './router'
import messagesShared, { mergeMessages } from "@shared/i18n";
import localMessages from "./i18n";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueClickAway from "vue3-click-away";
import axios from '@shared/services/axiosProvider';
import SmxForm from "@shared/components/elements/SmxForm.vue"
import SmxFormItem from "@shared/components/elements/SmxFormItem.vue"
import SmxFormSubtitle from "@shared/components/elements/SmxFormSubtitle.vue"
import { AuthErrorInterceptor, AuthInterceptor } from '@shared/services/interceptor';
import { Vue3Mq } from "vue3-mq";
import VueObserveVisibility from 'vue-observe-visibility'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const app = createApp(App)

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "de", // set locale
  fallbackLocale: "en", // set fallback locale
  messages: mergeMessages(messagesShared, localMessages),
});

axios.interceptors.request.use(
  AuthInterceptor(
    {
      postLogout: () => {
        router.push({ name: "login" });
      },
    },
    router
  ),
  () => {
    return;
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  AuthErrorInterceptor({
    postLogout: () => {
      router.push({ name: "login" });
    },
  })
);

// TODO: check which icons are still in use and remove succesively
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


// Font awesome icons
library.add(faPlus);
library.add(faQrcode);
library.add(faPenToSquare);
library.add(faMagnifyingGlass);


app.component("SmxForm", SmxForm);
app.component("SmxFormItem", SmxFormItem);
app.component("SmxFormSubtitle", SmxFormSubtitle);

app.component('FontAwesomeIcon', FontAwesomeIcon);
app.use(Vue3Mq, {
  preset: 'tailwind'
});
app.use(createPinia())
app.use(router)
app.use(ElementPlus);
app.use(i18n);
app.use(VueClickAway);
app.use(VueObserveVisibility)
app.mount('#app')


