<template lang="pug">
el-config-provider(:locale="elementPlusLanguage")
  SmxBaseLayout
    template(#navbar)
      Navbar(          
        v-if="!isPublicPage"
        :post-logout="logoutCallback"
        title=""
        logo-path="/img/logo.png"
        logo-path-small="/img/logo-small.png"
        )
    template(#menu)
      SmxMenu(v-if="!isPublicPage")        
    .app-content(
      class="md:p-10 max-md:p-5")
      RouterView(:key="route.path")
</template>

<script setup lang="ts">
import { computed, onMounted, watch, ref } from 'vue'
import { RouterLink, RouterView, useRoute, useRouter } from 'vue-router'
import Navbar from '@shared/components/Header.vue'
import { useI18n } from 'vue-i18n'
import { publicPages } from '@shared/router/loginGuard'
import { useAuthStore } from '../../shared/src/stores/auth'
import { useAppStore } from '../../shared/src/stores/apps'
import de from 'element-plus/dist/locale/de.mjs'
import en from 'element-plus/dist/locale/de.mjs'
import SmxMenu from '@shared/components/SmxMenu.vue'
import { useMenuStore } from '../../shared/src/stores/menu'
import SmxBaseLayout from '@shared/views/SmxBaseLayout.vue'
import { useMyAccountStore } from '../../shared/src/stores/myAccount'
import { whArticlesSingle, whArticlesMultiple } from '../../shared/src/warehouse/constants'
import { initWarehouseApp } from '../../shared/src/warehouse/hooks/warehouseInit'
import { initAssetManagementApp } from '../../shared/src/assetManagement/hooks/initAssetManagement'
import { useKeyCloakAuthentication } from '../../shared/src/hooks/authentication'
import useMenu from '@shared/hooks/menu'
import { initTicketManagementApp } from '@shared/maintenance/hooks/initTicketManagement'
import { initItAssetManagementApp } from '@shared/itAssetManagement/hooks/initItAssetManagement'

const { t } = useI18n()

const myAccountStore = useMyAccountStore()
useKeyCloakAuthentication()
const { isPublicPage } = useMenu()
const elementPlusLanguage = computed(() => {
  switch (myAccountStore.language) {
    case 'en':
      return en
    default:
      return de
  }
})

const components = {
  Navbar
}

initWarehouseApp()
initAssetManagementApp()
initItAssetManagementApp()
initTicketManagementApp()

const appStore = useAppStore()

const menuStore = useMenuStore()

const logoutCallback = () => {
  router.push({ name: 'login' })
}

// const isPublicPage = computed(() => {

//   console.log(router.currentRoute.value.fullPath)
//   return
// });

onMounted(() => {
  appStore.loadAppsOverview({}, false)
})

const route = useRoute()
</script>

<style>
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.app-body {
  display: flex;
}

.app-content {
  width: 100%;
}
</style>
